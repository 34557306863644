<template>
  <v-data-table
    :headers="headers"
    :items="channels"
    :items-per-page="100"
    sort-by="name"
    dense
  >

  </v-data-table>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import store from "@/store";
import moment from "moment/moment";
import content from "@/layouts/Content";

export default {
  name: "AdChannelTotalStats",
  props: {
    filters: {
      type: Object,
      default: () => {},
    }
  },
  setup(props) {
    const headers = ref([
      {text:'Админ', value: 'admin_contact'},
      {text:'Название', value: 'name'},
      {text:'Потрачено', value: 'common_stats.spent'},
      {text:'Охват', value: 'common_stats.views'},
      {text:'Подп', value: 'common_stats.users'},
      {text:'CPI', value: 'common_stats.cpi'},
      {text:'К', value: 'common_stats.coeff'},
      {text:'CPM', value: 'common_stats.cpm'},
      {text:'Доход', value: 'common_stats.income'},
      {text:'Прибыль', value: 'common_stats.profit'},
      {text: 'Комментарий', value: 'comment'},
    ])

    const allPubs = computed(()=>store.getters.adPublications)
    const pubs = computed(()=>allPubs.value.filter(x=>x.status === 'Опубликован'))

    const allChannels = computed(() => {
      return store.state.adChannels
    })
    const channels = computed(() => {
      //в каждый из allChannels.value добавляем поле lastPub, а сам allChannels нужно реверсировать
      const sorted = allChannels.value
        .reverse()
        .map(x=>{
          x.pubs = pubs.value.filter(y=>y.ad_channel_id === x.id).map(y=>y.id)
          x.common_stats = fillStats(x)
          return x
        })
      console.log(sorted)
      return sorted.filter(x=>x)
    })

    const fillStats = (channel) =>{
      const users = props.filters.userType === 'bot' ? getBotUsers(channel) : getChannelUsers(channel)
      const pubs = getPubs(channel.id)
      const stats = {
        users,
        spent: pubs.reduce((acc, cur)=>acc+cur.price, 0),
        views: pubs.reduce((acc, cur)=>acc+cur.views, 0),
        // income: pubs.reduce((acc, cur)=>acc+cur.stats.sort((a,b)=>a.id<b.id?1:-1)[0].money, 0),
        income: 0
      }
      pubs.forEach(pub=>{
        const lastBotStat = getLastBotStat(pub)
        // const pubStats = pub.stats.sort((a,b)=>a.id<b.id?1:-1)[0]
        stats.income += lastBotStat.money
      })
      stats.profit = stats.income - stats.spent
      stats.cpm = stats.spent / stats.views * 1000
      stats.cpi = stats.spent / stats.users
      stats.coeff = stats.spent / stats.views
      return stats
    }

    const getBotUsers = channel =>{
      //нужно пройтись по каждому из channel.pubs и взять из него последнюю стату
      //потом взять из нее users_all
      let users = 0
      pubs.value.filter(x=>x.ad_channel_id === channel.id).forEach(pub=>{
        if (!pub.stats.bot) return 0
        const stats = getLastBotStat(pub)
        // let dates = Object.keys(pub.stats.bot);
        // let latestDate = new Date(Math.max.apply(null, dates.map(date => new Date(date))));
        // latestDate = moment(latestDate).format('YYYY-MM-DD')
        users += stats.users_all
      })
      return users
    }

    const getChannelUsers = channel =>{
      //нужно пройтись по каждому из channel.pubs и взять из него последнюю стату
      let users = 0
      pubs.value.filter(x=>x.ad_channel_id === channel.id).forEach(pub=>{
        let dates = Object.keys(pub.stats.channel);
        let latestDate = new Date(Math.max.apply(null, dates.map(date => new Date(date))));
        latestDate = moment(latestDate).format('YYYY-MM-DD')
        users += pub.stats.channel.subs_alive
      })
      return users
    }

    const getPubs = channel_id =>{
      return pubs.value.filter(x=>x.ad_channel_id === channel_id)
    }

    const getLastBotStat = pub =>{
      if (!pub.stats.bot) {
        console.log('getLastBotStat pub.stats.bot:', pub)
        return 0
      }
      let dates = Object.keys(pub.stats.bot);
      let latestDate = new Date(Math.max.apply(null, dates.map(date => new Date(date))));
      latestDate = moment(latestDate).format('YYYY-MM-DD')
      return pub.stats.bot[latestDate]
    }
    return {
      headers,
      channels,
    }
  },
}
</script>

<style scoped>

</style>
